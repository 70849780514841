<template>
	<div class="main-wrapper"> 
		<img :src="require('../assets/bg1.jpg')" class="bg" />
	
		<div class="wrapper"> 
		      <div class="logo">
		        <img :src="require('../assets/logo.png')" >
		      </div>

		     
		      
		      <form v-on:submit.prevent="onLogin" v-if="login.state == 'login'">
				   <h6 class="text-center mb-3"> ADMIN LOGIN</h6>
		        
		        <div class="alert" :class="[isError ? 'alert-danger' : 'alert-success' ]" v-if="errors.login.show">
		              {{ errors.login.msg }}
		          </div>
				   <p class="danger help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
		          
		        

		       
				<div class="form-group" >
		            <label for="formGroupExampleInput" class="text-dark">UserID:</label>
		            <input type="text" class="form-control" id="formGroupExampleInput" v-model="login.loginId">
		            <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginId }} </p>
		        </div>
				<div class="form-group" >
		            <label for="formGroupExampleInput" class="text-dark">PassCode:</label>
		            <input type="password" class="form-control" id="formGroupExampleInput" v-model="login.loginPass">
		            <p class="help-block" v-if="errors.login.loginId && errors.login.loginId !== ''"> {{ errors.login.loginPass }} </p>
		        </div>


		        <div class="form-group ">
		            <button type="submit" class="btn btn-second" id="loginBtn" v-if="!isLoading">LOG IN</button>
					<button disabled class="btn btn-second" id="loginBtn" v-if="isLoading">LOGGING IN....</button>
		        </div>

				
		        <div class="footer">
		          <span>&copy; CEZA v2.54</span>
		        </div>
		      </form>


		    </div>
				
	</div>
</template>

<script>
import qs from 'querystring'
	export default {
		name: 'Login',
		data: function() {
			return  {
				login: {
		          state: 'login',
		           loginId: "",
				   merchantId:"",
		          loginPass: "",
		        },
		        forgot: {
		        	loginId: "",
		        },
		        errors: {
		          login: {
		            msg: "",
		            show: false,
		            loginId: "",
		            loginPass: ""
		          }
		        },
		       	isLoading: false,
		        isError: false,
				
			}
		},
		methods: {
			
		
		    onLogin: function() {
				this.isLoading = true;
		        let hasError = false;

		       
		            if(this.login.loginId == '' || this.login.loginId == undefined || this.login.loginId == null) {



		              this.errors.login.loginId = "Please enter your User ID";
		              hasError = true;
		            } 
				

		        if(this.login.loginPass == '' || this.login.loginPass == undefined || this.login.loginPass == null) {
		          this.errors.login.loginPass = "Please enter your passcode.";
		          hasError = true;
		        } 

		        

		        if(hasError) {
		          return;
		        }

		        this.errors.login.show = false;

		     
		        const params = {
					loginId: this.login.loginId,
					loginHost: 'test.cloudchain.it',
					loginPass: this.login.loginPass,
				
		        }

		        const data = Object.keys(params)
		          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
		          .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/ax/login',
		        };

		        this.axios(options)
		          .then((response) => {
					this.isLoading = false;
		              //console.log(response.data.tokenId)
		              localStorage.setItem("tokenId",response.data.tokenId);
					  localStorage.setItem("userId",response.data.userId); 
		              window.location.href = "/#/";

		              this.isError = false;
		            }).catch((err) => {
						this.isLoading = false;
		            	this.isError = true;
		                this.errors.login.show = true;
		                this.errors.login.msg = err.response.data.msgText;
						setTimeout(()=>{
                            window.location.reload();
                    	}, 2000)
		                
		                 
		          })
		      },

		    isNumber: function(evt) {
		        evt = (evt) ? evt : window.event;
		        var charCode = (evt.which) ? evt.which : evt.keyCode;
		        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
		          evt.preventDefault();
		        } else {
		          return true;
		        }
		      },
		  
		    
		},
		beforeMount(){
	      
	    },
		mounted(){
			
		},
		watch: { 
			'login.loginId': function(newValue, oldValue) {
				
		        if(newValue != oldValue) {  

		        	this.errors.login.loginId  = '';
		        	this.errors.login.msg = '';
		        	this.errors.login.show = false;
		        }
		    },

		    'login.loginPass': function(newValue, oldValue) {
		        if(newValue != oldValue) {  
		        	this.errors.login.loginPass  = '';
		        	this.errors.login.msg = '';
		        	this.errors.login.show = false;
		        }
		    },
		}
	}
</script>

<style scoped lang="scss">
.bg{
	width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -9999;
    pointer-events: none;
    object-fit: cover;
}
 .main{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 0;
    background-image: url('../assets/bg1.jpg'), no-repeat;
    width: 100%;
	height: 100%;
	
	
    
  }


.blob img{
	display: flex;
	width: 15em;
	position: absolute;

}
.blob2 img{
	z-index: 999;
	top: 0;
	left: 0;
	transform: translate(350%, 450%) rotate(15deg) scale(1.4) !important;
	display: flex;
	width: 10em;
	position: absolute;
	-webkit-transform: rotate(15deg) scale(1.4);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
	
}

.otp-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1em;
          margin-top: 1em;
      div {
      margin: 0 .25em;

      .otp-input {
            width: 60px;
		    text-align: center;
		    outline: none;
		    box-shadow: none;
		    font-size: 1.5em;
		    outline: none;
		    border: #5F58A1;
		    color: #5F58A1;
		    border-radius: 10px;;
		    height: 60px;
		    padding: 0.25em 0.25em;
      }
    }
  }
  .text-dark{
	  color: #515151;
	}
	.title{
		color: #333 !important;
		margin-bottom: 20px;
	}
	.text-muted{
		color: #333;
		font-size: 15px;
	}
	.link{
		color: #5F58A1;
		margin-left: 30px;
		cursor: pointer;
	}
	.btn-group{
		background: linear-gradient(rgb 61 106 209);
	}
	 .btn-main{
	  background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width:100%;
		border-radius: 10px;
		padding:5px;
		border: 1px #007bff solid;
		color:white !important;
  }
  .btn-main:hover{
	  background-size:100% 100%;
    	color:white !important;
  }
  .btn-second{
	background-image: linear-gradient(135deg,#fe6601a5,#007bffb2 20%,#007bff 70%,#007bff)!important;
      background-size:0% 100%;
      background-repeat:no-repeat;
      transition:.4s;
      width:100%;
      border-radius: 10px;
      padding:5px;
      border: 1px #007bff solid !important;
      color: white !important;
  }
   .btn-second:hover{
	    background-size:100% 100%;
    	color:white !important;
  }
   .btn-second {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; width: 100%;
  }
  .text-dark{
	color: black !important;
  }
	  .forgot {
	        display: flex;
	    justify-content: end;
	  }
	   a {
	    color: #5F58A1;
	    text-decoration: underline;
	  }
	.btn-flag {
		border-radius: 6px !important;
		background-color: #5F58A1; 
    	background-image: linear-gradient(#5F58A1, #5F58A1);
		display: flex;
	    align-items: center;
	    justify-content: center;
	}
	.logo {
	    display:flex;
	    justify-content: center;
	    align-items: center;
	    margin-bottom: 1em;
	    img{
	      width: 10rem;  
	    }
	    
	  } 

	  .mobile-container {
      position: relative;
      .default-flag {
        width: 33px;
         margin-right:0.25em;
      }

      .country-list {
          position: absolute;
          background: #515151;
          color: #333;
          top: 70px;
          padding: 0.5em;
          height: 13em;
          overflow: auto;
              z-index: 3;
          .row {
            margin: 0 !important;
            .col-sm-6 {
                display: flex;
              padding: 0.5em;
              align-items: center;
              cursor: pointer;
              &:hover {
                background: darken(#ccc, 10%);
              }
              .country-name {
                margin-left: 0.5em;
              }

              span {
                font-size: 0.9em;
              }
            }
          }
      }
    }

    .flag-list-img {
      width: 33px;
    }

	.email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;
      

      .btn {
      	background: transparent;
        color: #515151;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 10px;;
		width: 100%;

        &.active {
		  background-color: #3D4FBB;
		  border: 1px solid #3D4FBB;
		  border-radius: 10px;;

        }
      }
 
    }

    .btn {
    	font-weight: bold;
    }
	 label, span{
		margin-bottom: 0px !important;
		color: white !important;
		font-size: 17px;
  }

	.main-wrapper {
		display: flex;
	    justify-content: center;
	    height: 100vh;
	    flex-direction: column;
	    align-items: center;
		overflow-y: auto;
		background-color: lightgray;
		
	}
	.small-img{
		width: 3rem;
	}

	.wrapper {
		z-index: -999 !important;
		padding: 2em;
    	background: O rgba(85, 79, 79, 0.656); 
		// background-color: #ffffff;
    	border-radius: 10px;;
	    display: flex;
	    flex-direction: column;
	    justify-content: space-evenly;
	    box-shadow: -1px 1px 20px 0px rgba(0,0,0,0.75);
		-webkit-box-shadow: -1px 1px 20px 0px rgba(0,0,0,0.75);
		-moz-box-shadow: -1px 1px 20px 0px rgba(0,0,0,0.75);
		width: 17em;
		z-index: 999 !important;
		position: relative;
		form {
	      color: black;
	    }
	}
	#lnkSignup {
		display:flex;
		align-items: center;
		justify-content: center;
		
		a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	 .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5F58A1; 
    bottom: 0.5em;
    width: 100%;
  }
  #captcha {
	  display: flex;
	  justify-content: center;
  }
 
#captcha .form-control {
	width: 5rem;
	padding: 5px 5px !important;
	margin-bottom: 10px !important;
	background: transparent;
	font-size: 2.5rem !important;
	font-family:'Times New Roman', Times, serif;
	border: 1px solid #5F58A1;
	border-radius: 10px;;
	color: #5F58A1 !important;
	text-align: center;
	}


	 @media (min-width: 250px) and (max-width: 500px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */
	   .blob img{
		display: none !important;
		}
		.logo{
			margin-bottom: 0px !important;
		}
		.email-or-mobile{
			margin-bottom: 0px !important;
		}
		label, .forgot{
			font-size: 10px !important;
			
		}
	   .wrapper {
			width: 12em !important;
		    z-index: 2;
			h4, .text-muted{
				font-size: 10px;
				color: black;
			}	
		}
		.footer {
			font-size: 10px;
		}
      

       
    }
     @media (min-width: 500px) and (max-width: 580px) {
		.blob img{
		display: none !important;
		}
		.logo{
			margin-bottom: 0px !important;
		}
		.email-or-mobile{
			margin-bottom: 0px !important;
		}
		label, .forgot{
			font-size: 10px !important;
		}
	   .wrapper {
			width: 12em !important;
		    z-index: 2;
			h4, .text-muted{
				font-size: 10px;
				color: black;
			}	
		}
		.footer {
			font-size: 10px;
		}


     }

    @media (min-width: 600px) and (max-width: 1024px) {
		
		.logo{
			margin-bottom: 0px !important;
		}
		.email-or-mobile{
			margin-bottom: 0px !important;
		}
		label, .forgot{
			font-size: 10px !important;
		}
	   .wrapper {
			width: 15em !important;
		    z-index: 2;
			h4, .text-muted{
				font-size: 10px;
				color: black;
			}	
		}
		.footer {
			font-size: 15px;
		}
       
       
    }
    @media only screen and (min-width: 1280) {
      
    
    }
</style>